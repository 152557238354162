<template>
  <Header/>
  <div class="single">
    <div class="container" v-once id="top">
      <h1>Политика в отношении обработки персональных данных</h1>
      <h2>1. Общие положения</h2>

      <p>1.1. Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и
        определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных Общества с ограниченной ответственностью «БИФРИ» (далее –
        Оператор).</p>

      <p>1.2. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных
        данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.</p>

      <p>1.3. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о
        посетителях веб-сайта https://www.i-space.pro/.</p>


      <h2>2. Основные понятия, используемые в Политике</h2>

      <p>2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</p>

      <p>2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения
        персональных данных);</p>

      <p>2.3. Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому
        адресу https://www.i-space.pro/;</p>

      <p>2.4. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных
        данных конкретному Пользователю или иному субъекту персональных данных;</p>

      <p>2.5. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без
        использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
        использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</p>

      <p>2.6. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта https://www.i-space.pro/;</p>

      <p>2.7. Пользователь – любой посетитель веб-сайта https://www.i-space.pro/;</p>

      <p>2.8. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</p>

      <p>2.9. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или
        на
        ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в
        информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</p>

      <p>2.10. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства,
        иностранному физическому или иностранному юридическому лицу;</p>

      <p>2.11. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления
        содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</p>


      <h2>3. Оператор может обрабатывать следующие персональные данные Пользователя</h2>

      <p>3.1. Фамилия, имя, отчество;</p>

      <p>3.2. Электронный адрес;</p>

      <p>3.3. Номера телефонов;</p>

      <p>3.4. Фотографии;</p>

      <p>3.6. Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;</p>

      <p>3.7. Сведения о семейном положении и составе семьи;</p>

      <p>3.9. Сведения о занимаемых ранее должностях и стаже работы, воинской обязанности, воинском учете;</p>

      <p>3.10. Также на Веб-сайте происходит сбор и обработка обезличенных данных о Пользователях (в т.ч. файлов «cookies») с помощью сервисов интернет-статистики (Яндекс
        Метрика, Гугл Аналитика и других).</p>

      <p>3.11. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные.</p>


      <h2>4. Цели обработки персональных данных</h2>

      <p>4.1. Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем.</p>

      <p>4.2. Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда
        может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты с пометкой «Отказ от уведомлений о новых продуктах и
        услугах
        и специальных предложениях».</p>

      <p>4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения
        качества сайта и его содержания.</p>

      <h2>5. Правовые основания обработки персональных данных</h2>

      <p>5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы,
        расположенные на сайте https://www.i-space.pro/. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие
        с
        данной Политикой.</p>

      <p>5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и
        использование технологии JavaScript).</p>


      <h2>6. Порядок сбора, хранения, передачи и других видов обработки персональных данных</h2>

      <p>6.1. Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для
        выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.</p>

      <p>6.2. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.</p>

      <p>6.3. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего
        законодательства.</p>

      <p>6.4. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомление на адрес
        электронной почты Оператора i-free@i-free.com с пометкой «Актуализация персональных данных».</p>

      <p>6.5. Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив
        Оператору уведомление посредством электронной почты на электронный адрес Оператора i-free@i-free.com с пометкой «Отзыв согласия на обработку персональных данных».</p>


      <h2>7. Трансграничная передача персональных данных</h2>

      <p>7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого
        предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.</p>

      <p>7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае
        наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого
        является
        субъект персональных данных.</p>


      <h2>8. Заключительные положения</h2>

      <p>8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью
        электронной почты i-free@i-free.com.</p>

      <p>8.2. Настоящая Политика может изменяться Оператором с учетом изменяющихся требований законодательства и развития технических и организационных мер защиты персональных
        данных. Относительно изменения Политики, Оператор уведомляет, путем замены действующей редакции, размещенной в сети Интернет, новой редакцией или публикацией изменений
        к
        такой Политике. Политика действует бессрочно до замены ее новой версией.</p>

      <p>8.3. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу https://www.i-space.pro/privacy/.</p>

      <p>8.4. Вопросы, которые не урегулированы настоящей Политикой, разрешаются в соответствии с законодательством Российской Федерации. В отношении защиты субъектов
        персональных данных граждан иных стран (не Российской Федерации) Оператором могут учитываться положения законодательства территории, на которой проживает такой
        гражданин, или иной территории относящейся к гражданину.</p>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "Privacy",
  components: {Header},
  methods: {
    toSection(section) {
      if (section) {
        let target = document.getElementById(section).getBoundingClientRect().top
        target && window.scrollTo(target - 80, 0)
      } else {
        window.scrollTo(0, 0)
      }
    }
  },
  mounted() {
    this.toSection(this.section)
  },
}
</script>

<style scoped>
.container {
  padding: 3em;
}
</style>